// src/components/AppLayout.js
import React from 'react';
import { Layout, Menu, Button, Typography } from 'antd';
import { DashboardOutlined, FileOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';

const { Header, Content, Sider, Footer } = Layout;
const { Text } = Typography;

const AppLayout = ({ children, userData, onLogout }) => {
  const location = useLocation();

  const items = [
    {
      key: 'dashboard',
      icon: <DashboardOutlined />,
      label: <Link to="/dashboard">Dashboard</Link>,
    },
    {
      key: 'report-entries',
      icon: <FileOutlined />,
      label: <Link to="/report-entries">Reports</Link>,
    },
    // Conditionally add User Management menu item only if is_org_admin is true
    ...(userData.is_org_admin ? [
      {
        key: 'user-management',
        icon: <UserOutlined />,
        label: <Link to="/user-management">User Management</Link>,
      }
    ] : [])
  ];

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        theme="light"
        style={{ boxShadow: '2px 0 6px rgba(0,21,41,.35)' }}
      >
        <div style={{ height: 32, margin: 16, background: 'rgba(0,0,0,.2)' }} />
        <Menu
          mode="inline"
          selectedKeys={[location.pathname.split('/')[1] || 'dashboard']}
          items={items}
          style={{ borderRight: 0 }}
        />
      </Sider>
      <Layout>
        <Header style={{ 
          background: '#fff', 
          padding: '0 16px', 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'space-between',
          boxShadow: '0 1px 4px rgba(0,21,41,.08)'
        }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <UserOutlined style={{ fontSize: 20, color: '#52c41a', marginRight: 8 }} />
            <Text strong>Welcome, {userData.user.username}!</Text>
          </div>
          <Button 
            type="link" 
            icon={<LogoutOutlined />} 
            onClick={onLogout}
            style={{ color: '#1890ff' }}
          >
            Logout
          </Button>
        </Header>
        <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
          <div style={{ padding: 24, background: '#fff', borderRadius: 2 }}>
            {children}
          </div>
        </Content>
        <Footer style={{ textAlign: 'center', background: '#f0f2f5', padding: '12px 0' }}>
          Report Management System ©{new Date().getFullYear()} Created by Your Company
        </Footer>
      </Layout>
    </Layout>
  );
};

export default AppLayout;