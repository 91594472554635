// src/components/ReportEntries.js
import React, { useState, useEffect, useCallback } from 'react';
import { Table, Button, message, Row, Col, DatePicker } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import AddEntryForm from './AddEntryForm';
import EditEntryForm from './EditEntryForm';

const { RangePicker } = DatePicker;

const ReportEntries = () => {
  const [entries, setEntries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editingEntry, setEditingEntry] = useState(null);
  const [dateRange, setDateRange] = useState([]);
  const [isOrgAdmin, setIsOrgAdmin] = useState(false);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    setIsOrgAdmin(userData?.is_org_admin || false);
  }, []);

  const fetchEntries = useCallback(async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      let url = isOrgAdmin ? 'https://api-report-management-v3.uttarakhand.tech/api/org-report-entries/' : 'https://api-report-management-v3.uttarakhand.tech/api/user-report-entries/';
      if (dateRange.length === 2) {
        const startDate = dateRange[0].format('YYYY-MM-DD');
        const endDate = dateRange[1].format('YYYY-MM-DD');
        url += `?start_date=${startDate}&end_date=${endDate}`;
      }
      const response = await axios.get(url, {
        headers: { Authorization: `Token ${token}` }
      });
      setEntries(response.data);
    } catch (error) {
      message.error('Failed to fetch report entries');
    } finally {
      setLoading(false);
    }
  }, [dateRange, isOrgAdmin]);

  useEffect(() => {
    fetchEntries();
  }, [fetchEntries]);

  // const formatUserName = (user) => {
  //   if (!user) return 'Unknown User';
  //   const fullName = [user.first_name, user.last_name].filter(Boolean).join(' ');
  //   return fullName ? `${fullName} (${user.username})` : user.username;
  // };

  const formatUserName = (user) => {
    if (!user) return 'Unknown User';
    return [user.first_name, user.last_name].filter(Boolean).join(' ') || user.username;
  };

  const columns = [
    {
      title: 'Organization',
      dataIndex: ['organization', 'name'],
      key: 'organization',
    },
    {
      title: 'Department',
      dataIndex: ['department', 'name'],
      key: 'department',
    },
    {
      title: 'Entry Name',
      dataIndex: ['entry_name', 'name'],
      key: 'entry_name',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
    },
    {
      title: 'Entry Date',
      dataIndex: 'entry_date',
      key: 'entry_date',
    },
    {
      title: 'Entered By',
      dataIndex: 'user',
      key: 'user',
      render: (user) => formatUserName(user),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Button 
          type="default"
          onClick={() => handleEdit(record)}
        >
          Edit
        </Button>
      ),
    },
  ];

  const showAddModal = () => {
    setIsAddModalVisible(true);
  };

  const handleAddCancel = () => {
    setIsAddModalVisible(false);
  };

  const handleAddSuccess = () => {
    setIsAddModalVisible(false);
    fetchEntries();
  };

  const handleEdit = (record) => {
    setEditingEntry(record);
    setIsEditModalVisible(true);
  };

  const handleEditCancel = () => {
    setIsEditModalVisible(false);
    setEditingEntry(null);
  };

  const handleEditSuccess = () => {
    setIsEditModalVisible(false);
    setEditingEntry(null);
    fetchEntries();
  };

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  return (
    <div>
      <h2>{isOrgAdmin ? 'All Department Report Entries' : 'Your Report Entries'}</h2>
      <Row justify="space-between" style={{ marginBottom: 16 }}>
        <Col>
          <Button 
            type="primary" 
            icon={<PlusOutlined />}
            onClick={showAddModal} 
            style={{ 
              backgroundColor: 'rgb(0, 185, 107)',
              borderColor: 'rgb(0, 185, 107)'
            }}
          >
            Add New Entry
          </Button>
        </Col>
        <Col>
          <RangePicker onChange={handleDateRangeChange} />
        </Col>
      </Row>
      <Table 
        dataSource={entries} 
        columns={columns} 
        rowKey="id" 
        loading={loading}
      />
      <AddEntryForm
        visible={isAddModalVisible}
        onCancel={handleAddCancel}
        onSuccess={handleAddSuccess}
      />
      <EditEntryForm
        visible={isEditModalVisible}
        onCancel={handleEditCancel}
        onSuccess={handleEditSuccess}
        entry={editingEntry}
      />
    </div>
  );
};

export default ReportEntries;