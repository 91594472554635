// src/components/Dashboard.js
import React, { useEffect, useState } from 'react';
import { Row, Col, Card, List, message } from 'antd';
import axios from 'axios';
import UserDetails from './UserDetails';
import DepartmentStatistics from './DepartmentStatistics';

const Dashboard = () => {
  const [userData, setUserData] = useState(null);
  const [departmentStats, setDepartmentStats] = useState([]);

  useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      setUserData(JSON.parse(storedUserData));
    }
    
    fetchDepartmentStatistics();
  }, []);

  const fetchDepartmentStatistics = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://api-report-management-v3.uttarakhand.tech/api/department-statistics/', {
        headers: { Authorization: `Token ${token}` }
      });
      setDepartmentStats(response.data);
    } catch (error) {
      message.error('Failed to fetch department statistics');
    }
  };

  if (!userData) {
    return <div>Loading...</div>;
  }

  const getOrganizationInfo = () => {
    const info = [];
    if (userData.organization) {
      info.push({ label: "Organization", value: userData.organization.name || 'N/A' });
    }
    if (userData.departments && userData.departments.length > 0) {
      info.push({ label: "Departments", value: userData.departments.map(dept => dept.name).join(", ") });
    }
    return info;
  };

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Card title="User Information" style={{ height: '100%' }}>
            <UserDetails userData={userData} />
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Organization Information" style={{ height: '100%' }}>
            <List
              size="small"
              dataSource={getOrganizationInfo()}
              renderItem={item => (
                <List.Item>
                  <strong>{item.label}:</strong> {item.value}
                </List.Item>
              )}
            />
          </Card>
        </Col>
        <Col span={24}>
          <DepartmentStatistics statistics={departmentStats} />
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;