// src/components/DepartmentStatistics.js
import React from 'react';
import { Card, Table } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

const DepartmentStatistics = ({ statistics }) => {
  const columns = [
    {
      title: 'Department',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Entries',
      dataIndex: 'entries',
      key: 'entries',
      render: (entries) => {
        const color = entries > 0 ? '#3f8600' : entries === 0 ? 'inherit' : '#cf1322';
        const Icon = entries > 0 ? ArrowUpOutlined : entries === 0 ? null : ArrowDownOutlined;
        return (
          <span style={{ color }}>
            {Icon && <Icon style={{ marginRight: 4 }} />}
            {entries} entries
          </span>
        );
      },
    },
  ];

  return (
    <Card title="Department Statistics">
      <Table 
        dataSource={statistics} 
        columns={columns} 
        pagination={false}
        rowKey="name"
      />
    </Card>
  );
};

export default DepartmentStatistics;