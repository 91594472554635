// src/components/AddEntryForm.js
import React, { useState, useEffect } from 'react';
import { Modal, Form, Select, InputNumber, DatePicker, message } from 'antd';
import axios from 'axios';

const { Option } = Select;

const AddEntryForm = ({ visible, onCancel, onSuccess }) => {
  const [form] = Form.useForm();
  const [departments, setDepartments] = useState([]);
  const [entryNames, setEntryNames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(null);

  useEffect(() => {
    if (visible) {
      fetchDepartments();
    }
  }, [visible]);

  const fetchDepartments = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://api-report-management-v3.uttarakhand.tech/api/user-departments/', {
        headers: { Authorization: `Token ${token}` }
      });
      setDepartments(response.data);
    } catch (error) {
      message.error('Failed to fetch departments');
    }
  };

  const fetchEntryNames = async (departmentId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`https://api-report-management-v3.uttarakhand.tech/api/entry-names/?department_id=${departmentId}`, {
        headers: { Authorization: `Token ${token}` }
      });
      setEntryNames(response.data);
    } catch (error) {
      message.error('Failed to fetch entry names');
    }
  };

  const handleDepartmentChange = (value) => {
    setSelectedDepartment(value);
    form.setFieldsValue({ entry_name: undefined });
    fetchEntryNames(value);
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post('https://api-report-management-v3.uttarakhand.tech/api/create-report-entry/', {
        department: values.department,
        entry_name: values.entry_name,
        value: values.value,
        entry_date: values.entry_date.format('YYYY-MM-DD')
      }, {
        headers: { 
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json'
        }
      });
      console.log('Server response:', response.data);
      message.success('Entry added successfully');
      form.resetFields();
      onSuccess();
    } catch (error) {
      console.error('Error details:', error.response?.data);
      if (error.response && error.response.data && error.response.data.detail) {
        message.error('Failed to add entry: ' + error.response.data.detail);
      } else {
        message.error('Failed to add entry. Please try again.');
      }
    }
    setLoading(false);
  };

  return (
    <Modal
      visible={visible}
      title="Add New Entry"
      onCancel={onCancel}
      onOk={() => form.submit()}
      confirmLoading={loading}
      okButtonProps={{
        style: { backgroundColor: 'rgb(0, 185, 107)', borderColor: 'rgb(0, 185, 107)' }
      }}
    >
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item name="department" rules={[{ required: true, message: 'Please select a department' }]}>
          <Select placeholder="Select Department" onChange={handleDepartmentChange}>
            {departments.map(dept => (
              <Option key={dept.id} value={dept.id}>{dept.name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="entry_name" rules={[{ required: true, message: 'Please select an entry name' }]}>
          <Select placeholder="Select Entry Name" disabled={!selectedDepartment}>
            {entryNames.map(entry => (
              <Option key={entry.id} value={entry.id}>{entry.name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="value" rules={[{ required: true, message: 'Please input a value' }]}>
          <InputNumber placeholder="Value" style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item name="entry_date" rules={[{ required: true, message: 'Please select a date' }]}>
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddEntryForm;